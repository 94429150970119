import React from 'react';
import Layout from '../component/layout';
import HomeImage from '../images/service-offshore-dev-image.png';
import ServicesHomeComponentV2 from '../component/servicesHomeComponentV2';
import WebsLogoSwiper from '../component/websLogoSwiper';
import ItExpandTeamComponent from '../component/Services/IT/ItExpandTeamComponent';
import BorderCards from '../component/BorderCards';
import Icon from '../images/card-icon.png';
import CardsComponent from '../component/CardsComponent';
import TechStackComponent from '../component/Services/techStackComponent';
import CustomDevelopmentVideoComponent from '../component/Services/CustomDevelopment/customDevelopmentVideoComponent';
import FooterComponent from '../component/footerComponent';
import AccordionComponentV2 from '../component/accordionComponentV2';

const ServiceOffshoreDev = () => {
  const seo = {
    title: 'Offshore Software Development Services | Motomtech',
    metaDesc:
      'Motomtech is a top rated offshore development company with employees in Albania, Croatia, Brazil, and more.',
  };

  const cards = [
    {
      icon: Icon,
      title: 'Access Top Talent For Lower Cost:',
      description:
        'Offshore software development enables businesses to tap into a global pool of highly skilled professionals at a fraction of the cost it would require to hire local talent. This not only reduces your overall operational expenses but also allows you to gain access to a broad spectrum of expertise in various technologies and industry practices.',
    },
    {
      icon: Icon,
      title: 'Maximize your Budget:',
      description:
        'With the cost-effectiveness of offshore software development, you can accomplish more with less. The cost savings allow you to allocate your budget towards other key areas of your business, such as marketing, sales, and customer service, thereby maximizing your return on investment.',
    },
    {
      icon: Icon,
      title: 'Reduced Attrition Rates:',
      description:
        'Employee turnover can be costly and disruptive. However, by outsourcing your software development tasks offshore, you reduce your risk associated with employee attrition. Offshore companies manage their own teams, taking care of hiring, retention, and replacement, providing a stable and consistent workforce for your projects.',
    },
    {
      icon: Icon,
      title: 'Diversified Technology Experience:',
      description:
        'Offshore development teams often have a wide range of technological expertise due to working with diverse clients on various types of projects. This broad technical know-how ensures that they are equipped to handle unique project needs, adapt to new technologies, and provide innovative solutions.',
    },
    {
      icon: Icon,
      title: 'Quicker Ramp Up:',
      description:
        'With a readily available team of skilled professionals, offshore software development companies can start on your project much faster than if you had to assemble a team in-house. This means your software project can hit the ground running, enabling you to get your product to market quicker.',
    },
    {
      icon: Icon,
      title: '24/7 Productivity:',
      description:
        'One of the key benefits of offshore development is the ability to leverage time zone differences for round-the-clock productivity. While your local team rests, your offshore team can continue working, ensuring that your project progresses steadily, leading to faster completion times.',
    },
  ];

  const questions = [
    {
      hr: true,
      title:
        'How do you select the right offshore software development partner?',
      description:
        "Choosing the right offshore software development partner is crucial to the success of your project. You should start by clearly defining your project needs and goals. Once you have a clear vision of what you want to achieve, research potential partners to see if their expertise and services align with your requirements. Look for companies with a proven track record, testimonials, and case studies. Consider their technical expertise, communication protocols, project management style, and cultural compatibility. It's also important to evaluate their adherence to quality standards and data security measures. If possible, arrange a meeting, virtual or face-to-face, to further assess their capabilities and compatibility.",
    },
    {
      hr: true,
      title: 'When should I hire offshore developers?',
      description:
        "The decision to hire offshore developers can depend on several factors. If you have a large project that needs a dedicated team but you lack the required resources or expertise in-house, hiring offshore developers can be an ideal solution. Additionally, if you're facing budget constraints but don't want to compromise on the quality of your software, offshore development offers access to skilled professionals at a fraction of the cost. You should also consider hiring offshore developers if you need to scale your team quickly, handle overflow work, or if you want to benefit from round-the-clock productivity due to differing time zones",
    },
    {
      hr: false,
      title: 'When should I hire offshore developers?',
      description:
        'Effective management of offshore development teams begins with clear and open communication. Establish regular check-ins and updates to stay informed about project progress and address issues promptly. Utilize project management and collaboration tools to track tasks, share files, and facilitate communication. Set clear expectations from the start regarding work hours, deadlines, quality standards, and other important project details. Cultivating a culture of trust and respect can also greatly enhance the productivity and motivation of the offshore team. Remember, even though your team may be located halfway around the world, they are still a part of your larger team and should be treated as such.',
    },
  ];

  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={'Offshore Software Development Services'}
        homeDescription={
          "Motomtech is your ideal partner for offshore software development services, leveraging an exceptional team of expert programmers, state-of-the-art technologies, and a deep understanding of global business requirements. Our robust and scalable solutions are designed to seamlessly blend with your operations, ensuring that you stay ahead of the curve in an increasingly competitive digital landscape. With Motomtech, you're not just outsourcing your software development - you're gaining a strategic ally dedicated to your success."
        }
        homeImage={HomeImage}
        homeButton={'Request our services'}
        isGatsby={false}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={'Develop Your Software With the Top 1% of Software Developers'}
        description1={
          'At Motomtech, we pride ourselves on being the top 1% in the software development industry. Our team is made up of the best and brightest software engineers from around the globe, each one handpicked for their specialized skills and unmatched experience. Choosing us for your offshore software development means you get access to this exceptional pool of talent, providing you with robust, scalable, and innovative software solutions that outperform expectations.'
        }
        description2={
          'Our vast experience in the technology sector spans various industries, including healthcare, finance, retail, and many more. This rich industry experience, combined with our deep technical expertise, positions us uniquely to understand and address your specific needs. No matter the complexity or scale of your project, we have the resources and capabilities to deliver high-quality software solutions that align with your business objectives.'
        }
        description3={
          'One key advantage of partnering with Motomtech is our dedication to fostering long-term partnerships with our clients. We believe that offshore software development is not just a project, but a strategic move to help your business stay agile and competitive in a rapidly evolving digital landscape. We work closely with you, offering end-to-end support from the conceptualization stage to post-launch support, and every step in between.'
        }
        description4={
          'Moreover, at Motomtech, we adhere to the highest standards of software development, applying agile methodologies and ensuring rigorous quality control. As a testament to our quality, we have a track record of delivering projects on time and within budget, leading to a high client satisfaction rate.'
        }
        description5={
          "In conclusion, leveraging Motomtech for your offshore software development needs is more than just outsourcing. It's about gaining a committed partner that brings exceptional technical prowess, industry experience, and a customer-centric approach to help your business reach new heights. Choose Motomtech, where innovation and excellence are part of our DNA."
        }
      />
      <div
        style={{
          background:
            'linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)',
        }}
      >
        <p className='section-container-title'>
          What Our Offshore Software Development Services Cover
        </p>

        <BorderCards
          borderCardsText1={'Offshore Custom Software Development:'}
          borderCardsText2={
            'At Motomtech, we excel in providing tailor-made software solutions that perfectly fit your business requirements. Our skilled software developers are proficient in various technologies and can craft bespoke software that optimizes your operations, enhances efficiency, and gives you a competitive edge in your industry.'
          }
          borderCardsText3={'Offshore Mobile and Web App Development:'}
          borderCardsText4={
            'We specialize in creating robust and user-friendly mobile and web applications that delight your customers and drive engagement. Leveraging cutting-edge technologies and industry best practices, our offshore app development team turns your ideas into highly interactive, responsive, and feature-packed applications.'
          }
          borderCardsText5={'Offshore QA Services:'}
          borderCardsText6={
            'Quality is at the heart of all we do at Motomtech. Our offshore Quality Assurance services ensure that the software we develop is top-notch, thoroughly tested, and bug-free. With a meticulous approach to every detail, we confirm that your software meets the highest standards of performance, functionality, and user experience.'
          }
          borderCardsText7={'Offshore Software Maintenance:'}
          borderCardsText8={
            'We understand that maintaining software is as important as developing it. Our offshore software maintenance service includes regular updates, performance optimization, issue resolution, and preventive measures to ensure that your software stays up-to-date, secure, and functional, enhancing its lifespan and reliability.'
          }
          borderCardsText9={'Offshore IT Staff Augmentation:'}
          borderCardsText10={
            "Motomtech's offshore IT staff augmentation service allows you to scale your team up or down based on your project needs. Whether you need additional resources for a short-term project or require specialized skills for a complex task, we can provide highly skilled professionals who seamlessly blend with your team and contribute to your success."
          }
          borderCardsText11={'Offshore Software Testing:'}
          borderCardsText12={
            'Offshore Software Testing: With our comprehensive offshore software testing services, we guarantee your software is faultless and ready for the market. Our rigorous testing methodologies, combined with the latest testing tools, ensure your software product is thoroughly checked for errors, bugs, and compatibility issues, paving the way for a smooth and successful launch.'
          }
        />
      </div>
      <ItExpandTeamComponent
        title={'What is Offshore Software Development?'}
        description1={
          'Offshore software development refers to the practice of hiring an external company or team located in a different country to handle various software development tasks. This can encompass a wide range of services, including custom software development, mobile app development, QA testing, IT staff augmentation, and more. The primary objective of offshoring is to leverage global talent and lower operational costs while maintaining a high standard of quality and efficiency in software development processes.'
        }
        description2={
          'The primary advantage of offshore software development is cost-effectiveness. Companies can significantly reduce their operational expenses as the cost of hiring developers in countries such as India, Ukraine, or the Philippines is significantly lower than in countries like the U.S or Western Europe. Moreover, offshoring also provides access to a broader pool of talent and expertise, allowing businesses to leverage global knowledge and skills. It also enables companies to focus on their core business areas while the offshore team handles the software development tasks.'
        }
        description3={
          'However, offshoring also comes with its challenges, including communication barriers due to language and cultural differences, and time zone differences which can affect coordination and timely delivery. These challenges can be managed effectively with clear communication protocols, use of collaboration tools, and flexible work schedules.'
        }
        description4={
          'Nearshore and onshore software development are alternatives to offshoring. Nearshoring refers to outsourcing software development tasks to a country that is geographically close to your own, thus minimizing the time zone differences and facilitating easier communication. Onshore software development, on the other hand, involves outsourcing to a company or team within your own country. While these options may provide better communication and easier coordination, they often come with higher costs compared to offshore development.'
        }
        description5={
          "In conclusion, the choice between offshore, nearshore, or onshore software development largely depends on a company's specific needs, budget, and the complexity of the project. Each option comes with its advantages and challenges, and it is crucial for businesses to evaluate these factors before making a decision."
        }
      />
      <CardsComponent
        title={'Offshore Software Development Benefits'}
        showCards={true}
        cards={cards}
      />
      <TechStackComponent />
      <CustomDevelopmentVideoComponent />
      <AccordionComponentV2
        title={'Frequently Asked Questions'}
        questions={questions}
      />
      <FooterComponent />
    </Layout>
  );
};

export default ServiceOffshoreDev;
